import { format } from 'date-fns';
import { UserInfoDto } from 'src/Services/planetApi';
import { utils, writeFile } from 'xlsx';

export default function createReport(input: UserInfoDto[]) {
    var data = input.map(({ id, joinDate, ...props }) => {
        return {
            ...props,
            joinDate: !!joinDate ? format(new Date(joinDate), 'M/d/yyyy') : '',
        }
    });

    const columnDefs = [
        {id: "name", tit: "Name", wch: 15},
        {id: "lastName", tit: "Lastname", wch: 15},
        {id: "email", tit: "Email", wch: 25},
        {id: "points", tit: "Points", wch: 10},
        {id: "joinDate", tit: "Joining date", wch: 15}
    ];

    const worksheet = utils.json_to_sheet(data, { header: columnDefs.map(c => c.id) });
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Report");

    utils.sheet_add_aoa(worksheet, [columnDefs.map(c => c.tit)], { origin: "A1" });
    worksheet["!cols"] = columnDefs.map((c) => ({ wch: c.wch }));

    writeFile(workbook, "Report.xlsx", { compression: true });
} 
