import GiftIcon from '@heroicons/react/24/solid/GiftIcon';

type ComponentProps = {
  onClick: Function;
};

const RewardButton = (props: ComponentProps) => {
  return (
    <button
      className="bg-blue-500 px-2 py-2 rounded-md text-gray-800 font-semibold hover:bg-blue-600 hover:text-gray-900 mr-4"
      onClick={() => props.onClick()}
    >
      <GiftIcon className="w-5 h-5 fill-white" />
    </button>
  );
};

export default RewardButton;
