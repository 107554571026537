import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL, prepareHeaders(headers, api) {
    const OktaTokenStorage = localStorage.getItem('okta-token-storage');
    if (OktaTokenStorage) {
      const tokens = JSON.parse(OktaTokenStorage).accessToken;
      headers.set('Authorization', `Bearer ${tokens.accessToken}`)
    }
  }, },),
  endpoints: () => ({}),
})