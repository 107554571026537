import { Link } from 'react-router-dom';

const MenuNavigation = () => {
  return (
    <div className="flex flex-row font-semibold ml-auto mr-20">
      <Link to="/" className="mx-4">
        Insights
      </Link>
      <Link to="/user-profiles" className="flex flex-col mx-4">
        User Profiles
      </Link>
      <Link to="/user-suggestions" className="flex flex-col mx-4">
        User Suggestions
      </Link>
      <Link to="/user-claims" className="flex flex-col mx-4">
        User Claims
      </Link>
      <div className="flex flex-col group relative mx-4">
        <div className="cursor-pointer group-hover:underline">Settings</div>
        <div className="hidden group group-hover:flex flex-col absolute top-6 left-0 bg-white border border-gray-700 py-2 px-4 rounded-md">
          <Link to="/settings/activities" className="py-2">
            Activities
          </Link>
          <div className="w-full border border-gray-300"></div>
          <Link to="/settings/categories" className="py-2">
            Categories
          </Link>
          <div className="w-full border border-gray-300"></div>
          <Link to="/settings/rewards" className="py-2">
            Rewards
          </Link>
        </div>
      </div>
    </div>
  );
};
export default MenuNavigation;
