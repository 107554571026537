import ClickAwayListener from 'react-click-away-listener';
import {
  Reward,
  usePostApiRewardMutation,
  usePutApiRewardMutation,
} from 'src/Services/planetApi';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useState } from 'react';
import { produce } from 'immer';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { createPortal } from 'react-dom';
import NotificationService from 'src/Services/notificationService';

type ComponentProps = {
  reward: Reward;
  onCloseFn: Function;
  readonly: boolean;
};

const RewardModal = (props: ComponentProps) => {
  const [reward, setReward] = useState(props.reward);
  const [updateReward, updateStatus] = usePutApiRewardMutation();
  const [addReward, addStatus] = usePostApiRewardMutation();

  async function saveModel() {
    let response: any;
    const dto = produce(reward, (draft) => {
      draft.siteId = 1;
    });
    if (reward.id) {
      response = await updateReward({ reward: dto });
    } else {
      response = await addReward({
        reward: dto,
      });
    }
    if (response.error) {
      NotificationService.showError();

      return;
    } else {
      NotificationService.showSuccess();
      props.onCloseFn();
    }
  }

  function setName(name: string) {
    const updatedDto = produce(reward, (draft) => {
      draft.name = name;
    });
    setReward(updatedDto);
  }

  function setDescription(description: string) {
    const updatedDto = produce(reward, (draft) => {
      draft.description = description;
    });
    setReward(updatedDto);
  }

  function setPoints(points: number) {
    const updatedDto = produce(reward, (draft) => {
      draft.points = points;
    });
    setReward(updatedDto);
  }

  function onImageChange(image: any) {
    const updatedDto = produce(reward, (draft) => {
      draft.picture = image[0].dataURL;
    });
    setReward(updatedDto);
  }

  function removeImage() {
    const updatedDto = produce(reward, (draft) => {
      draft.picture = '';
    });
    setReward(updatedDto);
  }

  return createPortal(
    <div className="absolute top-0 left-0 bg-black w-screen h-screen bg-opacity-60 flex items-center">
      <ClickAwayListener onClickAway={() => props.onCloseFn()}>
        <div className="bg-white flex flex-col mx-auto container p-10 rounded-md w-6/12">
          <h1 className="text-2xl font-bold flex items-center">
            {props.reward.id ? 'Edit' : 'Add'} reward
            <XMarkIcon
              className="w-8 ml-auto cursor-pointer"
              onClick={() => props.onCloseFn()}
            />
          </h1>
          <div className="flex flex-col mt-10">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-lg font-medium select-none">
                Name
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-60 h-8 px-4"
                type="text"
                id="name"
                name="name"
                value={reward.name!}
                onChange={(e) => setName(e.target.value)}
                disabled={props.readonly}
              />
            </div>
            <div className="flex flex-col mt-5">
              <label
                htmlFor="description"
                className="text-lg font-medium select-none"
              >
                Description
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-[30rem] h-8 px-4"
                type="text"
                id="description"
                name="description"
                multiple
                value={reward.description!}
                disabled={props.readonly}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex flex-col mt-5">
              <label
                htmlFor="points"
                className="text-lg font-medium select-none"
              >
                Points
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-40 h-8 px-4"
                type="number"
                id="points"
                name="points"
                value={reward.points!}
                disabled={props.readonly}
                onChange={(e) => setPoints(Number.parseInt(e.target.value))}
              />
            </div>
          </div>
          <div className="flex flex-col mt-10">
            <label htmlFor="Image" className="text-lg font-medium select-none">
              Image
            </label>
            <ImageUploading
              value={reward.picture as any}
              onChange={onImageChange}
              maxNumber={1}
            >
              {({ onImageUpload, isDragging, dragProps }) => (
                <div className="upload__image-wrapper mt-4">
                  <div className="image-item">
                    <img
                      src={reward.picture || ''}
                      alt=""
                      width="400"
                      style={{ border: '1px solid black' }}
                    />
                    <div className="image-item__btn-wrapper w-52 flex">
                      {reward.picture && (
                        <button
                          className="text-sm font-semibold mx-auto mt-4 disabled:opacity-50"
                          onClick={() => removeImage()}
                          disabled={props.readonly}
                        >
                          Remove image
                        </button>
                      )}
                    </div>
                  </div>
                  {!reward.picture && (
                    <button
                      className="mt-10 text-sm font-semibold border border-dotted px-5 py-10 rounded-md border-green-500"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop image here
                    </button>
                  )}
                </div>
              )}
            </ImageUploading>
          </div>
          {!props.readonly && (
            <div className="mt-10 flex flex-row">
              <button
                className="ml-auto font-semibold border-gray-400 rounded-md px-4 py-1 select-none"
                onClick={() => props.onCloseFn()}
              >
                Cancel
              </button>
              <button
                className="ml-5 font-semibold rounded-md px-4 py-1 bg-[#6fcf97] hover:bg-[#52cf86] hover:text-gray-900 select-none"
                onClick={saveModel}
                disabled={addStatus.isLoading || updateStatus.isLoading}
              >
                {props.reward.id ? 'Update' : 'Add'}
              </button>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>,
    document.getElementById('modal')!
  );
};

export default RewardModal;
