export function toCoordinates(latitude: string | null | undefined, longitude: string | null | undefined) {
    return (latitude && longitude) ? `${latitude}, ${longitude}` : '';
}

export function fromCoordinates(input: string): [string?, string?] {
    const regex = /^-?[1-9]\d*(\.\d+),(\s)?-?[1-9]\d*(\.\d+)$/g;

    if (!input.match(regex)) {
        return [undefined, undefined];
    }

    const [latitude, longitude] = input.split(',').map(a => a.trim());
    const isValidLatitude = -90 < +latitude && +latitude < 90;
    const isValidLongitude = -180 < +longitude && +longitude < 180;

    return isValidLatitude && isValidLongitude ? [latitude, longitude] : [undefined, undefined];
}
