import { enqueueSnackbar } from "notistack";

class NotificationService {
    static showError(message?: string) {
        enqueueSnackbar({
            message: message ?? "There is an issue with your request. Please try again.",
            variant: 'error'
        })
    }

    static showSuccess(message?: string) {
        enqueueSnackbar({
            message: message ?? "Request was completed.",
            variant: 'success'
        })
    }
}
export default NotificationService;