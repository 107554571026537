import ArrowTopRightOnSquareIcon from '@heroicons/react/24/solid/ArrowTopRightOnSquareIcon';

export type MapButtonProps = {
    coordinates: string;
}

const MapButton = function (props: MapButtonProps) {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props.coordinates)}&zoom=20`;

    return <a href={url} className='flex items-center w-fit' target='_blank' rel="noopener noreferrer">
        Show
        <ArrowTopRightOnSquareIcon className='w-5 h-5 ml-1'></ArrowTopRightOnSquareIcon>
    </a>
}

export default MapButton;