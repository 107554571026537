import PlusSmallIcon from '@heroicons/react/24/solid/PlusSmallIcon';

type ComponentProps = {
  onClick: Function;
};

const AddButton = (props: ComponentProps) => {
  return (
    <button
      className="bg-[#6fcf97] px-2 py-2 rounded-md text-gray-800 font-semibold hover:bg-[#52cf86] hover:text-gray-900 flex items-center text-sm"
      onClick={() => props.onClick()}
    >
      <PlusSmallIcon className="w-5 h-5 fill-gray-800" />
      Add
    </button>
  );
};

export default AddButton;
