import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { ClaimedButton, RefreshButton } from 'src/Components/Buttons';
import NotificationService from 'src/Services/notificationService';
import {
  useGetApiUserClaimsQuery,
  usePostApiAdminFinishClaimMutation,
} from 'src/Services/planetApi';

const UserClaimsPage = () => {
  const { data: userClaims, isFetching, refetch } = useGetApiUserClaimsQuery();
  const [postFinishClaim] = usePostApiAdminFinishClaimMutation();

  async function finishClaim(claimId: number) {
    const response: any = await postFinishClaim({
      userClaimId: claimId,
    });

    if (response.error) {
      NotificationService.showError();
      return;
    }

    await refetch();
  }

  return (
    <div className="w-full">
      <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
        <div className="flex items-baseline pb-10 border-b-2 px-10">
          <h1 className="text-3xl font-bold">User Claims</h1>
          <h4 className="text-lg text-gray-400 font-bold ml-10">
            Los Angeles, U.S.
          </h4>
          <div className="ml-auto">
            <RefreshButton onClick={refetch} />
          </div>
        </div>
        <div className="px-10 bg-gray-50 pb-10">
          <div className="grid grid-cols-5 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
            <div className="col-span-1">User</div>
            <div className="col-span-1">Reward</div>
            <div className="col-span-1">Code</div>
            <div className="col-span-1">Date</div>
          </div>
          {isFetching && (
            <div className="pt-4">
              <Skeleton count={3} />
            </div>
          )}
          {!isFetching &&
            userClaims?.map((x, index) => (
              <div
                key={index}
                className="grid grid-cols-5 pt-4 text-gray-600 font-normal items-center"
              >
                <div className="col-span-1">{`${x.user?.name} ${x.user?.lastName}`}</div>
                <div className="col-span-1">{x.reward?.name}</div>
                <div className="col-span-1">{x.rewardCode}</div>
                <div className="col-span-1">
                  {' '}
                  {format(new Date(x.claimDate!), 'M/d/yyyy')}
                </div>
                <div className="ml-auto">
                  <ClaimedButton onClick={() => finishClaim(x.id!)} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserClaimsPage;
