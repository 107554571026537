import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import { format } from "date-fns";
import ClickAwayListener from "react-click-away-listener";
import { createPortal } from "react-dom";
import { useGetApiUserDetailQuery } from "src/Services/planetApi";

type UserDetailModalProps = {
    userId: number;
    onClose: () => void;
  };
  


const UserDetailModal = ({userId, onClose}: UserDetailModalProps) => {
    const {data: userDetail } = useGetApiUserDetailQuery({userId}); 
 
return createPortal(
    <div className="absolute top-0 left-0 bg-black w-screen h-screen bg-opacity-60 flex items-center">
        <ClickAwayListener onClickAway={onClose}>
        <div className="bg-white flex flex-col mx-auto container p-10 rounded-md w-6/12">
            <h1 className="text-2xl font-bold flex items-center">
                <XMarkIcon
                    className="w-8 ml-auto cursor-pointer"
                    onClick={onClose}
                    />
            </h1>
            {userDetail ? 
            <div className="flex flex-col gap-5">
                <div className="flex flex-row w-full justify-start gap-14">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row">
                            <span className="text-lg font-bold">Name:</span>
                            <span className="text-lg font-medium">&nbsp;{`${userDetail?.name} ${userDetail?.lastName}`}</span>
                        </div>
                        <div className="flex flex-row">
                            <span className="text-lg font-bold">Email:</span>
                            <span className="text-lg font-medium">&nbsp;{userDetail?.email}</span>
                        </div>
                        <div className="flex flex-row">
                            <span className="text-lg font-bold">Points:</span>
                            <span className="text-lg font-medium">&nbsp;{userDetail?.points}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row">
                            <span className="text-lg font-bold">Joined:</span>
                            <span className="text-lg font-medium">&nbsp;{format(new Date(userDetail?.joinDate!), 'M/d/yyyy')}</span>
                        </div>
                        {userDetail.favoriteActivity && <>
                            <div className="flex flex-row">
                                <span className="text-lg font-bold">Favorite Activity:</span>
                                <span className="text-lg font-medium">&nbsp;{userDetail.favoriteActivity.activity?.name!}</span>
                            </div>
                                <div className="flex flex-row">
                                <span className="text-lg font-bold">Favorite Activity Count:</span>
                                <span className="text-lg font-medium">&nbsp;{userDetail.favoriteActivityCount}</span>
                            </div>
                        </>}
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div className="text-lg font-bold">Rewards:</div>
                    <div className="flex flex-col w-full overflow-y-auto max-h-48">
                        <div className="flex flex-row justify-start font-bold">
                            <span className="w-48 overflow-x-auto">Name</span>
                            <span className="w-32 overflow-x-auto">Points</span>
                            <span className="w-32 overflow-x-auto">Claimed on</span>
                        </div>
                        {userDetail.claims?.map((claim, i) => {
                            return  <div key={i} className="flex flex-row justify-start font-medium">
                                        <span className="w-48 overflow-x-auto">{claim.reward?.name}</span>
                                        <span className="w-32 overflow-x-auto">{claim.reward?.points}</span>
                                        <span className="w-32 overflow-x-auto">{format(new Date(claim.claimDate as string), 'M/d/yyyy')}</span>
                                    </div>
                        })}
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div className="text-lg font-bold">Friends:</div>
                    <div className="flex flex-col w-full overflow-y-auto max-h-48">
                        <div className="flex flex-row justify-start font-bold">
                            <span className="w-48 overflow-x-auto">Name</span>
                            <span className="w-32 overflow-x-auto">Points</span>
                            <span className="w-72 overflow-x-auto">Email</span>
                        </div>
                        {userDetail.friends?.map((friend, i) => {
                            return  <div key={i} className="flex flex-row justify-start font-medium">
                                        <span className="w-48 overflow-x-auto">{friend.name}</span>
                                        <span className="w-32 overflow-x-auto">{friend.points}</span>
                                        <span className="w-80 overflow-x-auto">{friend.email}</span>
                                    </div>
                        })}
                    </div>
                </div>
            </div>
            :  <div className="m-auto font-bold">
                    Processing...
                </div>}
        </div>
        </ClickAwayListener>
    </div>,
    document.getElementById('modal')!
)}


export default UserDetailModal;