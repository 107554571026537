import { Route, Routes, useNavigate } from 'react-router';
import { ProtectedLayout } from './Components/Layout';
import { HomePage } from './Pages/Home';
import { LoginPage } from './Pages/Login';
import { CategoriesPage, ActivitiesPage, RewardsPage } from './Pages/Settings';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { NoAccessPage } from './Pages/NoAccess';
import { UserSuggestionsPage } from './Pages/UserSuggestions';
import { UserClaimsPage } from './Pages/UserClaims';
import { UserProfilesPage } from './Pages/UserProfiles';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'groups'],
});

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/user-profiles" element={<UserProfilesPage />} />
          <Route path="/user-suggestions" element={<UserSuggestionsPage />} />
          <Route path="/user-claims" element={<UserClaimsPage />} />
          <Route path="/settings/activities" element={<ActivitiesPage />} />
          <Route path="/settings/categories" element={<CategoriesPage />} />
          <Route path="/settings/rewards" element={<RewardsPage />} />
        </Route>
        <Route path="/no-access" element={<NoAccessPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/callback" element={<LoginCallback />} />
      </Routes>
    </Security>
  );
}

export default App;
