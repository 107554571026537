import { TextField } from "@mui/material";
import { MapButton } from "./Buttons";
import { useEffect, useState } from "react";
import { fromCoordinates, toCoordinates } from "src/Services/utils";

export type LocationInputProps = {
    latitude: string | null | undefined;
    longitude: string | null | undefined;
    onChange: (latitude?: string, longitude?: string) => void;
    readonly: boolean;
    inputClassName?: string;
}

const LocationInput = (props: LocationInputProps) => {
    const [data, setData] = useState<{ coordinates: string, valid: boolean }>({ coordinates: '', valid: false });

    useEffect(() => {
        setData({
            coordinates: toCoordinates(props.latitude, props.longitude),
            valid: !!props.latitude && !!props.longitude
        });
    }, []);

    function changeCoordinates(input: string) {
        const [latitude, longitude] = fromCoordinates(input);

        setData({
            coordinates: input,
            valid: !!latitude && !!longitude
        });

        props.onChange(latitude, longitude);
    }

    return <div className="flex flex-row gap-4">
        <TextField
            className={props.inputClassName}
            id="location-coordinates"
            label="Location"
            variant="outlined"
            value={data.coordinates}
            onChange={(e) => changeCoordinates(e.target.value as string)}
            disabled={props.readonly}
        />
        {
            (data.valid) && <MapButton coordinates={data.coordinates}></MapButton>
        }
    </div>
}

export default LocationInput;