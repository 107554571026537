import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { ReactComponent as LogoIcon } from 'src/Assets/logo.svg';
import { MenuNavigation } from 'src/Components/MenuNavigation';

const ProtectedLayout = () => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authState?.isAuthenticated === false) {
      navigate('/login');
    }
  }, [authState]);

  return (
    <div className="p-10 h-full">
      <div className="flex items-center">
        <LogoIcon className="w-80" />
        <div className="ml-4 font-bold">Site Admin</div>
        <MenuNavigation />
      </div>
      <div className="flex items-center container mx-auto mt-[5%]">
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;
