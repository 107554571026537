import { useGetApiUserAllQuery } from "src/Services/planetApi";
import { DetailButton, RefreshButton } from 'src/Components/Buttons';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { useState } from "react";
import { UserDetailModal } from "src/Components/Modals";
import createReport from "./createReport";
import BaseButton from "src/Components/Buttons/BaseButton";

const UserProfilesPage = () => {
    const { data: users, isFetching, refetch } = useGetApiUserAllQuery();
    const [ userId, setUserId ] = useState<number | null>(null);

    const sortedUsers = users ? [...users].sort((a, b) => b.points! - a.points! ) : [];

    function download() {
      if (sortedUsers) createReport(sortedUsers);
    }

    return (
        <div className="w-full">
        <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
          <div className="flex items-baseline pb-10 border-b-2 px-10">
            <h1 className="text-3xl font-bold">User Profiles</h1>
            <h4 className="text-lg text-gray-400 font-bold ml-10">
              Los Angeles, U.S.
            </h4>
            <div className="ml-auto">
              <RefreshButton onClick={refetch} />
            </div>
            <div className="ml-5">
              <BaseButton title='Export' onClick={download} />
            </div>
          </div>
          <div className="px-10 bg-gray-50 pb-10">
            <div className="grid grid-cols-5 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
              <div className="col-span-1">User</div>
              <div className="col-span-1">Email</div>
              <div className="col-span-1 mx-auto">Points</div>
              <div className="col-span-1 mx-auto">Join Date</div>
            </div>
            {isFetching && (
              <div className="pt-4">
                <Skeleton count={3} />
              </div>
            )}
            {!isFetching &&
              sortedUsers.map((user, index) => (
                <div
                  key={index}
                  className="grid grid-cols-5 pt-4 text-gray-600 font-normal items-center"
                >
                  <div className="col-span-1">{`${user?.name} ${user?.lastName}`}</div>
                  <div className="col-span-1">{user?.email}</div>
                  <div className="col-span-1 mx-auto">{user?.points}</div>
                  <div className="col-span-1 mx-auto">
                    {' '}
                    {user?.joinDate && format(new Date(user?.joinDate), 'M/d/yyyy')}
                  </div>
                  <div className="ml-auto"><DetailButton onClick={() => setUserId(user?.id!)}/></div>
                </div>
              ))}
          </div>
        </div>
        {userId && (<UserDetailModal userId={userId}  onClose={() => setUserId(null)}/>)}
      </div>
    )
};


export default UserProfilesPage;