import { baseApi as api } from "./baseApi";
export const addTagTypes = [
  "Activity",
  "Admin",
  "Category",
  "Reward",
  "Site",
  "Submissions",
  "User",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiActivity: build.query<
        GetApiActivityApiResponse,
        GetApiActivityApiArg
      >({
        query: () => ({ url: `/api/activity` }),
        providesTags: ["Activity"],
      }),
      postApiActivity: build.mutation<
        PostApiActivityApiResponse,
        PostApiActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity`,
          method: "POST",
          body: queryArg.activity,
        }),
        invalidatesTags: ["Activity"],
      }),
      putApiActivity: build.mutation<
        PutApiActivityApiResponse,
        PutApiActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity`,
          method: "PUT",
          body: queryArg.activity,
        }),
        invalidatesTags: ["Activity"],
      }),
      deleteApiActivity: build.mutation<
        DeleteApiActivityApiResponse,
        DeleteApiActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity`,
          method: "DELETE",
          body: queryArg.activity,
        }),
        invalidatesTags: ["Activity"],
      }),
      postApiActivityScanActivity: build.mutation<
        PostApiActivityScanActivityApiResponse,
        PostApiActivityScanActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity/scanActivity`,
          method: "POST",
          body: queryArg.scanActivityDto,
        }),
        invalidatesTags: ["Activity"],
      }),
      postApiAdminRewardUser: build.mutation<
        PostApiAdminRewardUserApiResponse,
        PostApiAdminRewardUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/admin/rewardUser`,
          method: "POST",
          body: queryArg.submissionRewardDto,
        }),
        invalidatesTags: ["Admin"],
      }),
      postApiAdminFinishClaim: build.mutation<
        PostApiAdminFinishClaimApiResponse,
        PostApiAdminFinishClaimApiArg
      >({
        query: (queryArg) => ({
          url: `/api/admin/finishClaim`,
          method: "POST",
          params: { userClaimId: queryArg.userClaimId },
        }),
        invalidatesTags: ["Admin"],
      }),
      getApiCategory: build.query<
        GetApiCategoryApiResponse,
        GetApiCategoryApiArg
      >({
        query: () => ({ url: `/api/category` }),
        providesTags: ["Category"],
      }),
      postApiCategory: build.mutation<
        PostApiCategoryApiResponse,
        PostApiCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category`,
          method: "POST",
          body: queryArg.category,
        }),
        invalidatesTags: ["Category"],
      }),
      putApiCategory: build.mutation<
        PutApiCategoryApiResponse,
        PutApiCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category`,
          method: "PUT",
          body: queryArg.category,
        }),
        invalidatesTags: ["Category"],
      }),
      deleteApiCategory: build.mutation<
        DeleteApiCategoryApiResponse,
        DeleteApiCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category`,
          method: "DELETE",
          body: queryArg.category,
        }),
        invalidatesTags: ["Category"],
      }),
      postApiCategoryAction: build.mutation<
        PostApiCategoryActionApiResponse,
        PostApiCategoryActionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category/action`,
          method: "POST",
          body: queryArg.action,
        }),
        invalidatesTags: ["Category"],
      }),
      getApiReward: build.query<GetApiRewardApiResponse, GetApiRewardApiArg>({
        query: () => ({ url: `/api/reward` }),
        providesTags: ["Reward"],
      }),
      postApiReward: build.mutation<
        PostApiRewardApiResponse,
        PostApiRewardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reward`,
          method: "POST",
          body: queryArg.reward,
        }),
        invalidatesTags: ["Reward"],
      }),
      putApiReward: build.mutation<PutApiRewardApiResponse, PutApiRewardApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/reward`,
            method: "PUT",
            body: queryArg.reward,
          }),
          invalidatesTags: ["Reward"],
        }
      ),
      deleteApiReward: build.mutation<
        DeleteApiRewardApiResponse,
        DeleteApiRewardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reward`,
          method: "DELETE",
          body: queryArg.reward,
        }),
        invalidatesTags: ["Reward"],
      }),
      getApiSiteInfo: build.query<
        GetApiSiteInfoApiResponse,
        GetApiSiteInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/site/info`,
          params: { siteId: queryArg.siteId },
        }),
        providesTags: ["Site"],
      }),
      getApiSiteUserActivity: build.query<
        GetApiSiteUserActivityApiResponse,
        GetApiSiteUserActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/site/userActivity`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["Site"],
      }),
      getApiSiteAllUserActivity: build.query<
        GetApiSiteAllUserActivityApiResponse,
        GetApiSiteAllUserActivityApiArg
      >({
        query: () => ({ url: `/api/site/allUserActivity` }),
        providesTags: ["Site"],
      }),
      getApiSiteLeaderboard: build.query<
        GetApiSiteLeaderboardApiResponse,
        GetApiSiteLeaderboardApiArg
      >({
        query: () => ({ url: `/api/site/leaderboard` }),
        providesTags: ["Site"],
      }),
      getApiSiteSitePerformance: build.query<
        GetApiSiteSitePerformanceApiResponse,
        GetApiSiteSitePerformanceApiArg
      >({
        query: () => ({ url: `/api/site/sitePerformance` }),
        providesTags: ["Site"],
      }),
      getApiSubmission: build.query<
        GetApiSubmissionApiResponse,
        GetApiSubmissionApiArg
      >({
        query: () => ({ url: `/api/submission` }),
        providesTags: ["Submissions"],
      }),
      postApiSubmission: build.mutation<
        PostApiSubmissionApiResponse,
        PostApiSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/submission`,
          method: "POST",
          body: queryArg.submission,
        }),
        invalidatesTags: ["Submissions"],
      }),
      deleteApiSubmission: build.mutation<
        DeleteApiSubmissionApiResponse,
        DeleteApiSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/submission`,
          method: "DELETE",
          body: queryArg.deleteByIdDto,
        }),
        invalidatesTags: ["Submissions"],
      }),
      getApiUserAll: build.query<GetApiUserAllApiResponse, GetApiUserAllApiArg>(
        {
          query: () => ({ url: `/api/user/all` }),
          providesTags: ["User"],
        }
      ),
      getApiUserInfo: build.query<
        GetApiUserInfoApiResponse,
        GetApiUserInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/info`,
          params: { email: queryArg.email },
        }),
        providesTags: ["User"],
      }),
      getApiUserFriends: build.query<
        GetApiUserFriendsApiResponse,
        GetApiUserFriendsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/friends`,
          params: { userId: queryArg.userId, limit: queryArg.limit },
        }),
        providesTags: ["User"],
      }),
      getApiUserFriendRequests: build.query<
        GetApiUserFriendRequestsApiResponse,
        GetApiUserFriendRequestsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/friendRequests`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["User"],
      }),
      postApiUserAdd: build.mutation<
        PostApiUserAddApiResponse,
        PostApiUserAddApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/add`,
          method: "POST",
          body: queryArg.addUserDto,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserSendFriendRequest: build.mutation<
        PostApiUserSendFriendRequestApiResponse,
        PostApiUserSendFriendRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/sendFriendRequest`,
          method: "POST",
          body: queryArg.sendFriendRequestDto,
        }),
        invalidatesTags: ["User"],
      }),
      postApiUserChangeFriendRequest: build.mutation<
        PostApiUserChangeFriendRequestApiResponse,
        PostApiUserChangeFriendRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/changeFriendRequest`,
          method: "POST",
          body: queryArg.changeFriendRequestDto,
        }),
        invalidatesTags: ["User"],
      }),
      deleteApiUserRemoveFriend: build.mutation<
        DeleteApiUserRemoveFriendApiResponse,
        DeleteApiUserRemoveFriendApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/removeFriend`,
          method: "DELETE",
          body: queryArg.removeFriendDto,
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserSearch: build.query<
        GetApiUserSearchApiResponse,
        GetApiUserSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/search`,
          params: { searchValue: queryArg.searchValue },
        }),
        providesTags: ["User"],
      }),
      getApiUserDetail: build.query<
        GetApiUserDetailApiResponse,
        GetApiUserDetailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/detail`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["User"],
      }),
      postApiUserClaim: build.mutation<
        PostApiUserClaimApiResponse,
        PostApiUserClaimApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/claim`,
          method: "POST",
          body: queryArg.claimRewardDto,
        }),
        invalidatesTags: ["User"],
      }),
      getApiUserClaims: build.query<
        GetApiUserClaimsApiResponse,
        GetApiUserClaimsApiArg
      >({
        query: () => ({ url: `/api/user/claims` }),
        providesTags: ["User"],
      }),
      getApiUserUserClaims: build.query<
        GetApiUserUserClaimsApiResponse,
        GetApiUserUserClaimsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/userClaims`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ["User"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as planetApi };
export type GetApiActivityApiResponse = /** status 200 Success */ Activity[];
export type GetApiActivityApiArg = void;
export type PostApiActivityApiResponse = /** status 202 Accepted */ undefined;
export type PostApiActivityApiArg = {
  activity: Activity;
};
export type PutApiActivityApiResponse = /** status 202 Accepted */ undefined;
export type PutApiActivityApiArg = {
  activity: Activity;
};
export type DeleteApiActivityApiResponse = /** status 202 Accepted */ undefined;
export type DeleteApiActivityApiArg = {
  activity: Activity;
};
export type PostApiActivityScanActivityApiResponse =
  /** status 200 Success */ boolean;
export type PostApiActivityScanActivityApiArg = {
  scanActivityDto: ScanActivityDto;
};
export type PostApiAdminRewardUserApiResponse =
  /** status 202 Accepted */ undefined;
export type PostApiAdminRewardUserApiArg = {
  submissionRewardDto: SubmissionRewardDto;
};
export type PostApiAdminFinishClaimApiResponse =
  /** status 202 Accepted */ undefined;
export type PostApiAdminFinishClaimApiArg = {
  userClaimId?: number;
};
export type GetApiCategoryApiResponse = /** status 200 Success */ Category[];
export type GetApiCategoryApiArg = void;
export type PostApiCategoryApiResponse = /** status 202 Accepted */ undefined;
export type PostApiCategoryApiArg = {
  category: Category;
};
export type PutApiCategoryApiResponse = /** status 202 Accepted */ undefined;
export type PutApiCategoryApiArg = {
  category: Category;
};
export type DeleteApiCategoryApiResponse = /** status 202 Accepted */ undefined;
export type DeleteApiCategoryApiArg = {
  category: Category;
};
export type PostApiCategoryActionApiResponse =
  /** status 202 Accepted */ Action;
export type PostApiCategoryActionApiArg = {
  action: Action;
};
export type GetApiRewardApiResponse = /** status 200 Success */ Reward[];
export type GetApiRewardApiArg = void;
export type PostApiRewardApiResponse = /** status 202 Accepted */ undefined;
export type PostApiRewardApiArg = {
  reward: Reward;
};
export type PutApiRewardApiResponse = /** status 202 Accepted */ undefined;
export type PutApiRewardApiArg = {
  reward: Reward;
};
export type DeleteApiRewardApiResponse = /** status 202 Accepted */ undefined;
export type DeleteApiRewardApiArg = {
  reward: Reward;
};
export type GetApiSiteInfoApiResponse = /** status 200 Success */ SiteInfoDto;
export type GetApiSiteInfoApiArg = {
  siteId?: number;
};
export type GetApiSiteUserActivityApiResponse =
  /** status 200 Success */ UserActivity[];
export type GetApiSiteUserActivityApiArg = {
  userId?: number;
};
export type GetApiSiteAllUserActivityApiResponse =
  /** status 200 Success */ UserActivity[];
export type GetApiSiteAllUserActivityApiArg = void;
export type GetApiSiteLeaderboardApiResponse =
  /** status 200 Success */ LeaderboardDto[];
export type GetApiSiteLeaderboardApiArg = void;
export type GetApiSiteSitePerformanceApiResponse =
  /** status 200 Success */ SitePerformanceDto[];
export type GetApiSiteSitePerformanceApiArg = void;
export type GetApiSubmissionApiResponse =
  /** status 200 Success */ Submission[];
export type GetApiSubmissionApiArg = void;
export type PostApiSubmissionApiResponse = /** status 202 Accepted */ undefined;
export type PostApiSubmissionApiArg = {
  submission: Submission;
};
export type DeleteApiSubmissionApiResponse =
  /** status 202 Accepted */ undefined;
export type DeleteApiSubmissionApiArg = {
  deleteByIdDto: DeleteByIdDto;
};
export type GetApiUserAllApiResponse = /** status 200 Success */ UserInfoDto[];
export type GetApiUserAllApiArg = void;
export type GetApiUserInfoApiResponse = /** status 200 Success */ UserInfoDto;
export type GetApiUserInfoApiArg = {
  email?: string;
};
export type GetApiUserFriendsApiResponse =
  /** status 200 Success */ UserInfoDto[];
export type GetApiUserFriendsApiArg = {
  userId?: number;
  limit?: number;
};
export type GetApiUserFriendRequestsApiResponse =
  /** status 200 Success */ FriendRequestDto[];
export type GetApiUserFriendRequestsApiArg = {
  userId?: number;
};
export type PostApiUserAddApiResponse = unknown;
export type PostApiUserAddApiArg = {
  addUserDto: AddUserDto;
};
export type PostApiUserSendFriendRequestApiResponse = unknown;
export type PostApiUserSendFriendRequestApiArg = {
  sendFriendRequestDto: SendFriendRequestDto;
};
export type PostApiUserChangeFriendRequestApiResponse = unknown;
export type PostApiUserChangeFriendRequestApiArg = {
  changeFriendRequestDto: ChangeFriendRequestDto;
};
export type DeleteApiUserRemoveFriendApiResponse = unknown;
export type DeleteApiUserRemoveFriendApiArg = {
  removeFriendDto: RemoveFriendDto;
};
export type GetApiUserSearchApiResponse =
  /** status 200 Success */ UserInfoDto[];
export type GetApiUserSearchApiArg = {
  searchValue?: string;
};
export type GetApiUserDetailApiResponse =
  /** status 200 Success */ UserDetailDto;
export type GetApiUserDetailApiArg = {
  userId?: number;
};
export type PostApiUserClaimApiResponse = /** status 200 Success */ UserClaim;
export type PostApiUserClaimApiArg = {
  claimRewardDto: ClaimRewardDto;
};
export type GetApiUserClaimsApiResponse = /** status 200 Success */ UserClaim[];
export type GetApiUserClaimsApiArg = void;
export type GetApiUserUserClaimsApiResponse =
  /** status 200 Success */ UserClaim[];
export type GetApiUserUserClaimsApiArg = {
  userId?: number;
};
export type Action = {
  id?: number;
  name?: string | null;
  categoryId?: number;
};
export type Category = {
  id?: number;
  name?: string | null;
  description?: string | null;
  actions?: Action[] | null;
  coolDownHours?: number;
};
export type Activity = {
  id?: number;
  name?: string | null;
  points?: number;
  coolDownHours?: number;
  qrId?: string;
  categoryId?: number;
  actionId?: number;
  category?: Category;
  action?: Action;
  locationDescription?: string | null;
  locationLatitude?: string | null;
  locationLongitude?: string | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type DeviceType = "IPHONE" | "ANDROID";
export type ScanActivityDto = {
  userId?: number;
  qrCode?: string;
  deviceType?: DeviceType;
};
export type SubmissionRewardDto = {
  userId: number;
  points: number;
  submissionId: number;
};
export type Reward = {
  id?: number;
  name?: string | null;
  description?: string | null;
  picture?: string | null;
  points?: number;
  siteId?: number;
};
export type SiteInfoDto = {
  name?: string | null;
  goal?: number;
  goalCollected?: number;
  goalDate?: string | null;
};
export type User = {
  id?: number;
  name?: string | null;
  lastName?: string | null;
  email?: string | null;
  points?: number;
  joinDate?: string | null;
};
export type UserActivity = {
  id?: number;
  userId?: number;
  user?: User;
  activityId?: number;
  activity?: Activity;
  siteId?: number;
  activityDate?: string | null;
  deviceType?: DeviceType;
};
export type LeaderboardDto = {
  firstName?: string | null;
  lastName?: string | null;
  points?: number;
};
export type SitePerformanceDto = {
  date?: string;
  points?: number;
  numberOfUsers?: number;
  iphoneUsers?: number;
  androidUsers?: number;
};
export type Submission = {
  id?: number;
  title?: string | null;
  text?: string | null;
  createdDate?: string;
  categoryId?: number;
  category?: Category;
  userId?: number;
  user?: User;
  rewarded?: boolean;
};
export type DeleteByIdDto = {
  id?: number;
};
export type UserInfoDto = {
  id?: number | null;
  name?: string | null;
  lastName?: string | null;
  email?: string | null;
  points?: number | null;
  joinDate?: string | null;
};
export type FriendRequestDto = {
  id?: number;
  request?: UserInfoDto;
};
export type AddUserDto = {
  name?: string | null;
  lastName?: string | null;
  email?: string | null;
};
export type SendFriendRequestDto = {
  senderId?: number;
  receiverId?: number;
};
export type ChangeFriendRequestDto = {
  id?: number;
  accepted?: boolean;
};
export type RemoveFriendDto = {
  myId?: number;
  friendId?: number;
};
export type UserClaim = {
  id?: number;
  userId?: number;
  user?: User;
  rewardId?: number;
  reward?: Reward;
  claimDate?: string;
  rewardCode?: string | null;
  isClaimed?: boolean;
};
export type UserDetailDto = {
  id?: number | null;
  name?: string | null;
  lastName?: string | null;
  email?: string | null;
  points?: number | null;
  joinDate?: string | null;
  friends?: UserInfoDto[] | null;
  claims?: UserClaim[] | null;
  activities?: UserActivity[] | null;
  favoriteActivity?: UserActivity;
  favoriteActivityCount?: number | null;
};
export type ClaimRewardDto = {
  id?: number;
  userId?: number;
  user?: User;
  rewardId?: number;
  reward?: Reward;
};
export const {
  useGetApiActivityQuery,
  usePostApiActivityMutation,
  usePutApiActivityMutation,
  useDeleteApiActivityMutation,
  usePostApiActivityScanActivityMutation,
  usePostApiAdminRewardUserMutation,
  usePostApiAdminFinishClaimMutation,
  useGetApiCategoryQuery,
  usePostApiCategoryMutation,
  usePutApiCategoryMutation,
  useDeleteApiCategoryMutation,
  usePostApiCategoryActionMutation,
  useGetApiRewardQuery,
  usePostApiRewardMutation,
  usePutApiRewardMutation,
  useDeleteApiRewardMutation,
  useGetApiSiteInfoQuery,
  useGetApiSiteUserActivityQuery,
  useGetApiSiteAllUserActivityQuery,
  useGetApiSiteLeaderboardQuery,
  useGetApiSiteSitePerformanceQuery,
  useGetApiSubmissionQuery,
  usePostApiSubmissionMutation,
  useDeleteApiSubmissionMutation,
  useGetApiUserAllQuery,
  useGetApiUserInfoQuery,
  useGetApiUserFriendsQuery,
  useGetApiUserFriendRequestsQuery,
  usePostApiUserAddMutation,
  usePostApiUserSendFriendRequestMutation,
  usePostApiUserChangeFriendRequestMutation,
  useDeleteApiUserRemoveFriendMutation,
  useGetApiUserSearchQuery,
  useGetApiUserDetailQuery,
  usePostApiUserClaimMutation,
  useGetApiUserClaimsQuery,
  useGetApiUserUserClaimsQuery,
} = injectedRtkApi;
