import ClickAwayListener from 'react-click-away-listener';
import {
  Submission,
  usePostApiAdminRewardUserMutation,
} from 'src/Services/planetApi';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import NotificationService from 'src/Services/notificationService';

type ComponentProps = {
  submission: Submission;
  onCloseFn: Function;
};

const UserRewardModal = (props: ComponentProps) => {
  const [addPoints, addStatus] = usePostApiAdminRewardUserMutation();

  const [points, setPoints] = useState(10);

  async function saveModel() {
    const response: any = await addPoints({
      submissionRewardDto: {
        userId: props.submission.user?.id!,
        points: points,
        submissionId: props.submission.id!,
      },
    });

    if (response.error) {
      NotificationService.showError();

      return;
    } else {
      NotificationService.showSuccess(
        `User ${props.submission.user?.email} was awarded with ${points} points.`
      );
      props.onCloseFn();
    }
  }

  return createPortal(
    <div className="absolute top-0 left-0 bg-black w-screen h-screen bg-opacity-60 flex items-center">
      <ClickAwayListener onClickAway={() => props.onCloseFn()}>
        <div className="bg-white flex flex-col mx-auto container p-10 rounded-md w-6/12">
          <h1 className="text-2xl font-bold flex items-center">
            Reward {props.submission.user?.email}
            <XMarkIcon
              className="w-8 ml-auto cursor-pointer"
              onClick={() => props.onCloseFn()}
            />
          </h1>
          <div className="flex flex-col mt-10">
            <div className="flex flex-col">
              <label
                htmlFor="points"
                className="text-lg font-medium select-none"
              >
                Points
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-80 h-8 px-4"
                type="text"
                id="points"
                name="points"
                value={points!}
                onChange={(e) => setPoints(Number.parseInt(e.target.value))}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-row">
            <button
              className="ml-auto font-semibold border-gray-400 rounded-md px-4 py-1 select-none"
              onClick={() => props.onCloseFn()}
            >
              Cancel
            </button>
            <button
              className="ml-5 font-semibold rounded-md px-4 py-1 bg-[#6fcf97] hover:bg-[#52cf86] hover:text-gray-900 select-none"
              onClick={saveModel}
              disabled={addStatus.isLoading}
            >
              Reward
            </button>
          </div>
        </div>
      </ClickAwayListener>
    </div>,
    document.getElementById('modal')!
  );
};

export default UserRewardModal;
