import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { RefreshButton } from 'src/Components/Buttons';
import {
  useGetApiSiteLeaderboardQuery,
  useGetApiSiteSitePerformanceQuery,
} from 'src/Services/planetApi';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const HomePage = () => {
  const {
    data: users,
    refetch: refreshLeaderboard,
    isFetching,
  } = useGetApiSiteLeaderboardQuery();

  const {
    data: sitePerformance,
    refetch: refreshPerformance,
    isFetching: sitePerformanceFetching,
  } = useGetApiSiteSitePerformanceQuery();

  return (
    <div className="w-full mb-20">
      <div className="flex flex-col">
        <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
          <div className="flex items-baseline pb-10 border-b-2 px-10">
            <h1 className="text-3xl font-bold">Site Performance</h1>
            <h4 className="text-lg text-gray-400 font-bold ml-10">
              Los Angeles, U.S.
            </h4>
            <div className="ml-auto">
              <RefreshButton onClick={refreshPerformance} />
            </div>
          </div>
          <div className="px-10 bg-gray-50 pb-10 mt-10">
            {!sitePerformanceFetching && (
              <Line
                data={{
                  labels: sitePerformance?.map((x) =>
                    format(new Date(x.date!), 'M/d/yyyy')
                  ),
                  datasets: [
                    {
                      label: 'Total points collected',
                      data: sitePerformance?.map((x) => x.points),
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                      label: '# of users',
                      data: sitePerformance?.map((x) => x.numberOfUsers),
                      borderColor: 'rgb(252, 186, 3)',
                      backgroundColor: 'rgba(252, 186, 3, 0.5)',
                    }
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top' as const,
                      labels: {
                        padding: 30
                      }
                    },
                    title: {
                      display: false,
                      text: 'Site Performance',
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg mt-20">
          <div className="flex items-baseline pb-10 border-b-2 px-10">
            <h1 className="text-3xl font-bold">Leaderboard</h1>
            <h4 className="text-lg text-gray-400 font-bold ml-10">
              Los Angeles, U.S.
            </h4>
            <div className="ml-auto">
              <RefreshButton onClick={refreshLeaderboard} />
            </div>
          </div>
          <div className="px-10 bg-gray-50 pb-10">
            <div className="grid grid-cols-5 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
              <div className="col-span-1"></div>
              <div className="col-span-2">User</div>
              <div className="col-span-1">Points</div>
              <div className="col-span-1"></div>
            </div>
            {isFetching && (
              <div className="pt-4">
                <Skeleton count={3} />
              </div>
            )}
            {!isFetching &&
              users?.map((x, index) => (
                <div
                  key={index}
                  className="grid grid-cols-5 pt-4 text-gray-600 font-normal items-center"
                >
                  <div className="col-span-1">{index + 1}.</div>
                  <div className="col-span-2">{`${x.firstName} ${x.lastName}`}</div>
                  <div className="col-span-1">{x.points}</div>
                  <div className="col-span-1"></div>
                  <div className="ml-auto"></div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
