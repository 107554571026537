import TrashIcon from '@heroicons/react/24/solid/TrashIcon';

type ComponentProps = {
  onClick: Function;
};

const DeleteButton = (props: ComponentProps) => {
  return (
    <button
      className="bg-red-500 px-2 py-2 rounded-md text-gray-800 font-semibold hover:bg-red-600 hover:text-gray-900"
      onClick={() => props.onClick()}
    >
      <TrashIcon className="w-5 h-5 fill-white" />
    </button>
  );
};

export default DeleteButton;
