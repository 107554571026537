import { ReactComponent as LogoIcon } from 'src/Assets/logo.svg';
import { ReactComponent as EarthIcon } from 'src/Assets/earth.svg';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';

const NoAccessPage = () => {
  const { oktaAuth } = useOktaAuth();

  function login() {
    const originalUri = toRelativeUrl(
      window.location.origin,
      window.location.origin
    );
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  }

  return (
    <div className="p-10 overflow-hidden">
      <div className="flex items-center absolute">
        <LogoIcon className="w-80" />
        <div className="ml-4 font-bold">Site Admin</div>
      </div>
      <div className="flex items-center h-screen ">
        <div className="flex flex-row mx-auto px-10 bg-white shadow-2xl items-center rounded-xl border">
          <div className="">
            <div className="text-5xl font-semibold">Sign in</div>
            <div className="mt-4 text-lg font-semibold text-gray-500">
              You do not have access for this website. A priviledged account is
              required.
            </div>
          </div>
          <div className="ml-20 w-[35rem]">
            <EarthIcon className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccessPage;
