import ClickAwayListener from 'react-click-away-listener';
import {
  Category,
  usePostApiCategoryMutation,
  usePutApiCategoryMutation,
} from 'src/Services/planetApi';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useState } from 'react';
import PlusSmallIcon from '@heroicons/react/24/solid/PlusSmallIcon';
import MinusSmallIcon from '@heroicons/react/24/solid/MinusSmallIcon';
import { produce } from 'immer';
import { createPortal } from 'react-dom';
import NotificationService from 'src/Services/notificationService';

type ComponentProps = {
  category: Category;
  onCloseFn: Function;
  readonly: boolean;
};

const CategoryModal = (props: ComponentProps) => {
  const [category, setCategory] = useState(props.category);
  const [updateCategory, updateStatus] = usePutApiCategoryMutation();
  const [addCategory, addStatus] = usePostApiCategoryMutation();

  async function saveModel() {
    let response: any;
    const dto = produce(category, (draft) => {
      draft.actions = category.actions?.filter((x) => x?.name?.trim() !== '') || [];
    });
    if (category.id) {
      response = await updateCategory({ category: dto });
    } else {
      response = await addCategory({
        category: dto,
      });
    }
    if (response.error) {
      NotificationService.showError();

      return;
    } else {
      NotificationService.showSuccess();
      props.onCloseFn();
    }
  }

  function setName(name: string) {
    const updatedDto = produce(category, (draft) => {
      draft.name = name;
    });
    setCategory(updatedDto);
  }

  function setDescription(description: string) {
    const updatedDto = produce(category, (draft) => {
      draft.description = description;
    });
    setCategory(updatedDto);
  }

  function setCooldown(cooldown: string) {
    const parsedCooldown = Number(cooldown.replace(/\D/g,''));
    const updatedDto = produce(category, (draft) => {
      draft.coolDownHours = parsedCooldown;
    });
    setCategory(updatedDto);
  } 

  function addAction() {
    const updatedCategory = produce(category, (draft) => {
      if (draft.actions) {
        draft.actions?.push({});
      } else {
        draft.actions = [{}];
      }
    });
    setCategory(updatedCategory);
  }

  function changeAction(text: string, index: number) {
    const updatedCategory = produce(category, (draft) => {
      draft.actions![index].name = text;
    });
    setCategory(updatedCategory);
  }

  function removeAction(index: number) {
    const updatedCategory = produce(category, (draft) => {
      draft.actions?.splice(index, 1);
    });
    setCategory(updatedCategory);
  }

  return createPortal(
    <div className="absolute top-0 left-0 bg-black w-screen h-screen bg-opacity-60 flex items-center">
      <ClickAwayListener onClickAway={() => props.onCloseFn()}>
        <div className="bg-white flex flex-col mx-auto container p-10 rounded-md w-6/12">
          <h1 className="text-2xl font-bold flex items-center">
            {props.category.id ? 'Edit' : 'Add'} category
            <XMarkIcon
              className="w-8 ml-auto cursor-pointer"
              onClick={() => props.onCloseFn()}
            />
          </h1>
          <div className="flex flex-col mt-10">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-lg font-medium select-none">
                Name
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-80 h-8 px-4"
                type="text"
                id="name"
                name="name"
                value={category.name!}
                onChange={(e) => setName(e.target.value)}
                disabled={props.readonly}
              />
            </div>
            <div className="flex flex-col mt-5">
              <label
                htmlFor="description"
                className="text-lg font-medium select-none"
              >
                Description
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-80 h-8 px-4"
                type="text"
                id="description"
                name="description"
                value={category.description!}
                disabled={props.readonly}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex flex-col mt-5">
              <label
                htmlFor="cooldown"
                className="text-lg font-medium select-none"
              >
                Cooldown hours
              </label>
              <input
                className="border border-gray-400 rounded-md mt-2 w-80 h-8 px-4"
                type="text" 
                id="cooldown"
                name="cooldown"
                value={category.coolDownHours!}
                disabled={props.readonly}
                onChange={(e) => setCooldown(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row mt-5">
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="text-lg font-medium select-none">Actions</div>
                <button disabled={props.readonly} onClick={addAction}>
                  <PlusSmallIcon className="w-6 ml-8 cursor-pointer hover:fill-green-600" />
                </button>
              </div>
              <div>
                {category.actions?.map((item, index) => (
                  <div key={index} className="flex items-center mt-2">
                    <div className="mr-4">{index + 1}.</div>
                    <input
                      className="border border-gray-400 rounded-md w-80 h-8 px-4"
                      type="text"
                      value={item.name!}
                      onChange={(e) => changeAction(e.target.value, index)}
                      disabled={props.readonly}
                    />
                    <button
                      disabled={props.readonly}
                      onClick={() => removeAction(index)}
                    >
                      <MinusSmallIcon className="w-6 ml-4 cursor-pointer hover:fill-red-600" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!props.readonly && (
            <div className="mt-10 flex flex-row">
              <button
                className="ml-auto font-semibold border-gray-400 rounded-md px-4 py-1 select-none"
                onClick={() => props.onCloseFn()}
              >
                Cancel
              </button>
              <button
                className="ml-5 font-semibold rounded-md px-4 py-1 bg-[#6fcf97] hover:bg-[#52cf86] hover:text-gray-900 select-none"
                onClick={saveModel}
                disabled={addStatus.isLoading || updateStatus.isLoading}
              >
                {props.category.id ? 'Update' : 'Add'}
              </button>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>,
    document.getElementById('modal')!
  );
};

export default CategoryModal;
