import {
  Action,
  Activity,
  Category,
  usePostApiActivityMutation,
  usePostApiCategoryActionMutation,
  usePutApiActivityMutation,
} from 'src/Services/planetApi';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useEffect, useState } from 'react';
import { produce } from 'immer';
import { createPortal } from 'react-dom';
import QRCode from 'react-qr-code';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import LocationInput from '../LocationInput';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import NotificationService from 'src/Services/notificationService';

type ComponentProps = {
  activity: Activity;
  categories: Category[];
  onCloseFn: Function;
  readonly: boolean;
};

const ActivityModal = (props: ComponentProps) => {
  const [activity, setActivity] = useState(props.activity);
  const [actions, setActions] = useState<Action[]>([]);
  const [actionMode, setActionMode] = useState<'select' | 'new'>('select');
  const [newActionName, setNewActionName] = useState<string>('');

  const [updateActivity, updateStatus] = usePutApiActivityMutation();
  const [addActivity, addStatus] = usePostApiActivityMutation();
  const [addAction] = usePostApiCategoryActionMutation();

  const { categories } = props;

  async function saveModel() {
    let response: any;
    let createdAction: Action | undefined = undefined;

    if (actionMode === 'new') {
      const newActionResponse = await addAction({ action: { categoryId: activity.categoryId, name: newActionName } });

      if ('data' in newActionResponse && newActionResponse.data.id) {
        createdAction = newActionResponse.data;
      }
    }

    const activityDto = produce(activity, (draft) => {
      if (createdAction) {
        draft.action = createdAction;
        draft.actionId = createdAction.id;
        draft.name = createdAction.name;
      }
    });

    if (activityDto.id) {
      response = await updateActivity({ activity: activityDto });
    } else {
      response = await addActivity({ activity: activityDto });
    }

    if (response.error) {
      NotificationService.showError();

      return;
    } else {
      NotificationService.showSuccess();
      props.onCloseFn();
    }
  }

  function setLocation(latitude: string | null | undefined, longitude: string | null | undefined) {
    const updatedDto = produce(activity, (draft) => {
      draft.locationLatitude = latitude;
      draft.locationLongitude = longitude;
    });
    setActivity(updatedDto);
  }

  function setPoints(points: number) {
    const updatedDto = produce(activity, (draft) => {
      draft.points = points;
    });
    setActivity(updatedDto);
  }

  function setCategory(categoryId: string) {
    const updatedDto = produce(activity, (draft) => {
      draft.category = categories?.find(
        (x) => x.id === Number.parseInt(categoryId)
      );
      draft.categoryId = Number.parseInt(categoryId);
    });
    setActivity(updatedDto);
  }

  function setAction(actionId: string) {
    const updatedDto = produce(activity, (draft) => {
      const action = draft.category?.actions?.find(
        (x) => x.id === Number.parseInt(actionId)
      );

      draft.action = action;
      draft.actionId = Number.parseInt(actionId);
      draft.name = action?.name;
    });
    setActivity(updatedDto);
  }

  function setLocationDescription(description: string) {
    const updatedDto = produce(activity, (draft) => {
      draft.locationDescription = description
    });
    setActivity(updatedDto);
  }

  async function downloadQrCode() {
    htmlToImage
      .toPng(document.getElementById('qr-code')!)
      .then(function (dataUrl) {
        download(dataUrl, `${activity.name}.png`);
      });
  }

  useEffect(() => {
    const flatActions = categories.flatMap((x) => x.actions) || [];
    setActions(flatActions as Action[]);

    // category on activity entity is missing list of actions so take category from categories
    if (activity.categoryId) {
      setCategory(activity.categoryId.toString());
    }
  }, [categories]);

  return createPortal(
    <div className="absolute top-0 left-0 bg-black w-screen h-screen bg-opacity-60 flex items-center">
      <div className="bg-white flex flex-col mx-auto container p-10 rounded-md w-6/12">
        <h1 className="text-2xl font-bold flex items-center">
          {props.activity.id ? 'Edit' : 'Add'} reward {activity.name}
          <XMarkIcon
            className="w-8 ml-auto cursor-pointer"
            onClick={() => props.onCloseFn()}
          />
        </h1>
        <div className="flex flex-col">
          <div className="flex flex-col w-96 mt-8">
            <FormControl>
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={activity.categoryId}
                label="Category"
                disabled={props.readonly}
                onChange={(e) => setCategory(e.target.value as string)}
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-row w-auto mt-5">
            {!props.readonly && <ToggleButtonGroup
              className='mr-4'
              color="primary"
              value={actionMode}
              exclusive
              disabled={!activity.category || !activity.category.id}
              onChange={(_, newOption) => {
                if (newOption) {
                  setActionMode(newOption);
                }
              }}
            >
              <ToggleButton value="select">Select action</ToggleButton>
              <ToggleButton value="new">New action</ToggleButton>
            </ToggleButtonGroup>}
            {actionMode === 'select' && <FormControl className='w-64 mr-8'>
              <InputLabel id="action">Select action</InputLabel>
              <Select
                labelId="action"
                id="action"
                value={activity.actionId}
                label="Select action"
                onChange={(e) => setAction(e.target.value as string)}
                disabled={props.readonly || !activity.category || !activity.category.id}
              >
                {actions
                  ?.filter((x) => x.categoryId === activity.category?.id)
                  .map((action, index) => (
                    <MenuItem key={index} value={action.id}>
                      {action.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>}
            {actionMode === 'new' && <TextField
              className='w-64 mr-8'
              id="newActionName"
              type='text'
              label="Action name"
              value={newActionName}
              onChange={(e) => setNewActionName(e.target.value as string)}
              variant="outlined"
              disabled={props.readonly}
            />}
          </div>
          <div className="flex flex-col w-32 mt-5">
            <TextField
              id="points"
              label="Points"
              variant="outlined"
              value={activity.points}
              onChange={(e) => setPoints(Number.parseInt(e.target.value))}
              disabled={props.readonly}
            />
          </div>
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="Image" className="text-lg font-medium select-none">
            QR Code Location
          </label>
          <div className="mt-5">
            <TextField
              className='w-96'
              id="location-description"
              label="Description"
              multiline
              rows={2}
              value={activity.locationDescription}
              onChange={(e) => setLocationDescription(e.target.value as string)}
              disabled={props.readonly}
            />
          </div>
          <div className='mt-5'>
            <LocationInput
              latitude={activity.locationLatitude}
              longitude={activity.locationLongitude}
              onChange={(latitude, longitude) => setLocation(latitude, longitude)}
              readonly={props.readonly}
              inputClassName='w-96' />
          </div>
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="Image" className="text-lg font-medium select-none">
            QR Code
          </label>
          {activity.qrId && (
            <div>
              <div className="my-4 w-40 h-44 p-4 bg-white" id="qr-code">
                <QRCode value={activity.qrId!} size={128} />
              </div>
              {props.readonly && (
                <button
                  className="border px-4 py-1 rounded-md border-green-500 bg-green-500 text-white font-bold"
                  onClick={downloadQrCode}
                >
                  Download QR Code
                </button>
              )}
            </div>
          )}
        </div>
        {!props.readonly && (
          <div className="mt-10 flex flex-row">
            <button
              className="ml-auto font-semibold border-gray-400 rounded-md px-4 py-1 select-none"
              onClick={() => props.onCloseFn()}
            >
              Cancel
            </button>
            <button
              className="ml-5 font-semibold rounded-md px-4 py-1 bg-[#6fcf97] hover:bg-[#52cf86] hover:text-gray-900 select-none"
              onClick={saveModel}
              disabled={addStatus.isLoading || updateStatus.isLoading}
            >
              {props.activity.id ? 'Update' : 'Add'}
            </button>
          </div>
        )}
      </div>
    </div>,
    document.getElementById('modal')!
  );
};

export default ActivityModal;
