import BaseButton from "./BaseButton";

type ComponentProps = {
  onClick: Function;
};

const RefreshButton = (props: ComponentProps) => {
  return <BaseButton title='Refresh' onClick={props.onClick} />
};

export default RefreshButton;
