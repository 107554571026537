import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  DeleteButton,
  RefreshButton,
  RewardButton,
} from 'src/Components/Buttons';
import { UserRewardModal } from 'src/Components/Modals';
import NotificationService from 'src/Services/notificationService';
import {
  Submission,
  useDeleteApiSubmissionMutation,
  useGetApiSubmissionQuery,
} from 'src/Services/planetApi';

const UserSuggestionsPage = () => {
  const [rewardDto, setRewardDto] = useState<Submission | undefined>();
  const [viewMode, setViewMode] = useState<'all' | 'rewarded' | "not-rewarded">('all');
  const { data: submissions, isFetching, refetch } = useGetApiSubmissionQuery();

  const [deleteSubmission] = useDeleteApiSubmissionMutation();

  async function onDeleteSubmission(submission: Submission) {
    const response: any = await deleteSubmission({
      deleteByIdDto: {
        id: submission.id!,
      },
    });

    if (response.error) {
      NotificationService.showError();
      return;
    }
  }

  return (
    <div className="w-full">
      <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
        <div className="flex items-baseline pb-10 border-b-2 px-10">
          <h1 className="text-3xl font-bold">User Suggestions</h1>
          <h4 className="text-lg text-gray-400 font-bold ml-10">
            Los Angeles, U.S.
          </h4>
          <div className="flex ml-auto gap-4 ">
          <ToggleButtonGroup
              color="primary"
              value={viewMode}
              exclusive
              onChange={(_, newOption) => {
                if (newOption) {
                  setViewMode(newOption);
                }
              }}
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="rewarded">Rewarded</ToggleButton>
              <ToggleButton value="not-rewarded">Not rewarded</ToggleButton>
            </ToggleButtonGroup>
            <RefreshButton onClick={refetch} />
          </div>
        </div>
        <div className="px-10 bg-gray-50 pb-10">
          <div className="grid grid-cols-7 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
            <div className="col-span-1">User</div>
            <div className="col-span-1">Title</div>
            <div className="col-span-2">Description</div>
            <div className="col-span-1">Category</div>
            <div className="col-span-1">Date</div>
          </div>
          {isFetching && (
            <div className="pt-4">
              <Skeleton count={3} />
            </div>
          )}
          {!isFetching &&
            submissions?.filter((x) => {
              if (viewMode === 'all') return true;
              if (viewMode === 'rewarded') return x.rewarded === true;
              if (viewMode === 'not-rewarded') return x.rewarded === false;
            })
            .map((x, index) => (
              <div
                  key={index}
                  className={"grid grid-cols-7 pt-4 text-gray-600 font-normal"}
                >
                  <div className="col-span-1 break-words">{x.user?.email}</div>
                  <div className="col-span-1">{x.title}</div>
                  <div className="col-span-2">
                    <div className="px-2">{x.text}</div>
                  </div>
                  <div className="col-span-1">{x.category?.name}</div>
                  <div className="col-span-1">
                    {format(new Date(x.createdDate!), 'M/d/yyyy')}
                  </div>
                  <div className="ml-auto">
                    {!x.rewarded && <RewardButton onClick={() => setRewardDto(x)} /> }            
                    <DeleteButton onClick={() => onDeleteSubmission(x)} />
                  </div>
                </div>
            ))}
        </div>
      </div>
      {rewardDto && (
        <UserRewardModal
          submission={rewardDto}
          onCloseFn={() => {
            setRewardDto(undefined);
            refetch();
          }}
        />
      )}
    </div>
  );
};

export default UserSuggestionsPage;
