import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  AddButton,
  DeleteButton,
  DetailButton,
  EditButton,
  RefreshButton,
} from 'src/Components/Buttons';
import { RewardModal } from 'src/Components/Modals';
import NotificationService from 'src/Services/notificationService';
import {
  Reward,
  useDeleteApiRewardMutation,
  useGetApiRewardQuery,
} from 'src/Services/planetApi';

const RewardsPage = () => {
  const [editModel, setEditModel] = useState<Reward | undefined>();
  const [detailModel, setDetailModel] = useState<Reward | undefined>();

  const {
    data: rewards,
    isFetching: rewardsFetching,
    refetch: refreshRewards,
  } = useGetApiRewardQuery();

  const [deleteReward] = useDeleteApiRewardMutation();

  async function onDeleteReward(reward: Reward) {
    const response: any = await deleteReward({
      reward,
    });

    if (response.error) {
      NotificationService.showError();
      return;
    }
  }

  return (
    <div className="w-full">
      <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
        <div className="flex items-baseline pb-10 border-b-2 px-10">
          <h1 className="text-3xl font-bold">Rewards</h1>
          <h4 className="text-lg text-gray-400 font-bold ml-10">
            Los Angeles, U.S.
          </h4>
          <div className="ml-auto">
            <RefreshButton onClick={refreshRewards} />
          </div>
        </div>
        <div className="px-10 bg-gray-50 pb-10">
          <div className="grid grid-cols-6 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
            <div className="col-span-1">Picture</div>
            <div className="col-span-1">Name</div>
            <div className="col-span-2">Description</div>
            <div className="col-span-1">Points</div>
            <div className="col-span-1 ml-auto">
              <AddButton onClick={() => setEditModel({})} />
            </div>
          </div>
          {rewardsFetching && (
            <div className="pt-4">
              <Skeleton count={3} />
            </div>
          )}
          {!rewardsFetching &&
            rewards?.map((x, index) => (
              <div
                key={index}
                className="grid grid-cols-6 pt-4 text-gray-600 font-normal items-center"
              >
                <div className="col-span-1">
                  <img src={x.picture ? x.picture : ''} className="w-16" />
                </div>
                <div className="col-span-1">{x.name}</div>
                <div className="col-span-2">
                  <div className="px-2">{x.description}</div>
                </div>
                <div className="col-span-1">{x.points}</div>
                <div className="ml-auto">
                  <DetailButton onClick={() => setDetailModel(x)} />
                  <EditButton onClick={() => setEditModel(x)} />
                  <DeleteButton onClick={() => onDeleteReward(x)} />
                </div>
              </div>
            ))}
        </div>
      </div>
      {editModel && (
        <RewardModal
          reward={editModel!}
          onCloseFn={() => setEditModel(undefined)}
          readonly={false}
        />
      )}
      {detailModel && (
        <RewardModal
          reward={detailModel!}
          onCloseFn={() => setDetailModel(undefined)}
          readonly={true}
        />
      )}
    </div>
  );
};

export default RewardsPage;
